.adminControls {
  position: fixed;
  bottom: 0;
  right: 0;
  display: grid;
  min-width: 320px;

  .arrow {
    transform: rotate(180deg);
  }
}

.logo {
  width: 140px;
}

.root {
  background-color: transparent;
  box-shadow: none;
}

.expanded {
  margin: 0;
  min-width: 48px;
}

.section {
  margin-bottom: 8px;
}

.integrations {
  display: grid;

  .checkboxGroup {
    display: flex;
  }
}

.radioGroup {
  display: grid;
}

.buttonGroup {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  & > * {
    width: 48%;
  }
}

.limitEntry {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;

  .value {
    max-width: 72px;
  }
}