body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f8fb;
}

code {
  background-color: rgba(255,229,100,0.2);
  padding: 0 3px;
  font-size: 0.94em;
  word-break: break-word;
}

sub {
  color: rgba(0, 0, 0, 0.47);
}

h1 {
  padding-top: 1.5rem;
  font-size: 2rem;
}

a {
  color: #1764ff;
  text-decoration: none;
}

a:hover {
  color: #8dadf4;
}

.container {
  max-width: 720px;
  margin: 0 auto;
}

.form-control {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}

.form-control.hidden {
  display: none;
}

.form-control input {
  padding: 10px 8px;
  font-size: 16px;
  outline: none;
  transition: all 0.2s ease;
}

.form-control label {
  margin-bottom: 4px;
}

.auth-form {
  margin-top: 24px;
}

.promo-text {
  color: #1764ff;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 26px;
}

.card {
  width: 576px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(9, 36, 51, 0.08), 0 4px 16px rgba(9, 36, 51, 0.04);
}

.card-title {
  padding-top: 0;
  margin: 16px auto;
}

.card-body {
  padding: 24px 60px 40px;
}

.card-footer {
  font-size: 0.875rem;
}

.auth-form-content {
  margin-top: 20px;
}

.auth-button-wrapper {
  margin-top: 24px;
}

.auth-button {
  width: 100%;
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  background: #1764ff;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  border: none;
}

.auth-button:hover {
  background-color: #8dadf4;
  transition: .3s;
}

.auth-switcher-text {
  color: #5F717A;
  margin-bottom: 16px;
}

.conditions {
  margin-top: 24px;
  color: #5F717A;
}

.wrapper {
  width: 100%;
  margin-top: 24px;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}


.logo {
  height: 32px;
  width: 180px;
  object-fit: contain;
}

.security-code {
  margin-top: 20px;
}

.security-code .message, .auth-form .error {
  color: rgb(230, 50, 17);
  margin: 0;
}
